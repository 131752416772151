import React from 'react';
import { BoardItem as Item } from '../types';
import { applyColors } from '../utils';
import { Tag } from './Tag';

interface Props {
  item: Item;
}

export const BoardItem = ({ item }: Props) => {
  const itemImage = new URL(item.image);
  itemImage.searchParams.append('t', Date.now().toString());

  return (
    <div className="wrapper">
      <img className="label" src={itemImage.toString()} />
      <div className="tap-number">{item.tap_number}</div>
      <div className="main">
        <div className="name">
          <span>{item.brewery}</span> {item.name}
          {item.tags.map((t, idx) => (
            <Tag key={idx} {...t} />
          ))}
        </div>
        <div className="details">
          {item.abv ? `${item.abv}%\xa0\xa0·\xa0\xa0` : ''}
          {applyColors(item.style || item.description)}
        </div>
      </div>

      <div className="containers">
        {item.containers.map((c) => (
          <div key={c.id}>
            <div className="price">€{c.price}</div>
            <div className="size">{c.size}</div>
          </div>
        ))}
      </div>

      <style jsx>{`
        .wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
        }

        :global(.items-10) .wrapper {
          margin-bottom: 8px;
        }

        .wrapper:last-child {
          margin-bottom: 0;
        }

        .label,
        .tap-number {
          width: 112px;
          height: 112px;
        }

        :global(.items-10) .label,
        :global(.items-10) .tap-number {
          width: 96px;
          height: 96px;
        }

        .label {
          border-radius: 100%;
          margin-right: 16px;
          flex-shrink: 0;
          cursor: none;
          object-fit: cover;
        }

        .tap-number,
        .name,
        .price {
          font-size: 56px;
          line-height: 72px;
        }

        :global(.items-10) .tap-number,
        :global(.items-10) .name,
        :global(.items-10) .price {
          font-size: 56px;
          line-height: 56px;
        }

        .details,
        .size {
          font-size: 36px;
          line-height: 40px;
        }

        :global(.items-10) .details,
        :global(.items-10) .size {
          font-size: 32px;
          line-height: 32px;
        }

        .tap-number {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          margin-right: 8px;
        }

        .main {
          flex: 1;
          min-width: 0;
        }

        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .details :global(.yellow) {
          color: #ffe000;
        }

        .name span {
          color: rgba(255, 255, 255, 0.56);
        }

        .containers {
          min-width: 368px;
          display: flex;
        }

        .containers > div {
          min-width: 184px;
          text-align: right;
        }

        .size {
          color: rgba(255, 255, 255, 0.64);
        }
      `}</style>
    </div>
  );
};
