import React from 'react';
import { useRouter } from 'next/router';

import config from '../venues';
import { BoardItem as Item } from '../types';
import { BoardItem } from './BoardItem';

interface Props {
  items: Item[];
}

export const Board = ({ items }: Props) => {
  const router = useRouter();
  const page = typeof router.query.page === 'string' ? parseInt(router.query.page, 10) - 1 : null;
  const filtered = page !== null ? items.slice(page * config.pageSize, (page + 1) * config.pageSize) : items;

  return (
    <div className={`container items-${config.pageSize}`}>
      {filtered.map((item) => (
        <BoardItem key={item.id} item={item} />
      ))}
      <style jsx>{`
        .container {
          padding: 32px 40px;
        }

        .items-10 {
          padding: 24px 40px;
        }
      `}</style>
    </div>
  );
};
